import {
  Box,
  List,
  Theme,
  ListItem,
  Accordion,
  Typography,
  ListItemIcon,
  ListItemText,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import headerImage from 'assets/images/whats-been-done-header.jpg'

const StyledCheckIcon = () => (
  <CheckIcon
    sx={{
      color: (theme: Theme) => theme.palette.primary.main,
    }}
  />
)

const WhatsBeenDoneView = () => {
  return (
    <Box>
      <Box
        component="img"
        src={headerImage}
        sx={{
          width: '100%',
          height: '40vw',
          maxHeight: '30rem',
          objectFit: 'cover',
          objectPosition: '50% 65%',
          mb: '-5px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: (theme: Theme) => theme.palette.primary.main,
          color: '#FFFFFF',
          px: 4,
          py: 8,
        }}
      >
        <Typography variant="h5">Keeping Promises</Typography>
        <Typography variant="body1" sx={{ maxWidth: '40rem' }}>
          During the 2018 campaign, Bruce's primary commitments were 1) to
          improve safety for residents of all ages by making roads safer and 2)
          bring balance to the services and facilities that are available to
          Uxbridge's aging community and 3) continue to advocate for a new
          Long-Term Care facility to replace Reachview. He also committed to
          bringing a new approach to property taxes. These and other commitments
          have been fulfilled.
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: '60rem',
          mx: 'auto',
          my: 6,
        }}
      >
        {/* As Ward 3 Councillor */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: (theme: Theme) => theme.palette.secondary.main }}
              />
            }
          >
            <Typography variant="h6">As Ward 3 Councillor</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <ListItemIcon>
                  <StyledCheckIcon />
                </ListItemIcon>
                <ListItemText>
                  Speed limits have been reduced. First on Reach St and Highway
                  47, and then throughout the urban area.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledCheckIcon />
                </ListItemIcon>
                <ListItemText>
                  Lane narrowing lines have been installed.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledCheckIcon />
                </ListItemIcon>
                <ListItemText>
                  4-way stop signs have been put in place throughout Barton
                  Farms.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledCheckIcon />
                </ListItemIcon>
                <ListItemText>
                  Police enforcement has increased in Coral Creek and Barton
                  Farms.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledCheckIcon />
                </ListItemIcon>
                <ListItemText>
                  Herrema Park no longer experiences vandalism due to the
                  measures taken to prevent unauthorized access and parking lot
                  damage as well as the addition of security cameras.
                </ListItemText>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        {/* In support of Uxbridge Seniors */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: (theme: Theme) => theme.palette.secondary.main }}
              />
            }
          >
            <Typography variant="h6">In support of Uxbridge Seniors</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <ListItemIcon>
                  <StyledCheckIcon />
                </ListItemIcon>
                <ListItemText>
                  Bruce provided the leadership of an advocacy committee that
                  lead to the announcement of a new 192 bed Long-Term Care
                  facility in Uxbridge to replace Reachview.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledCheckIcon />
                </ListItemIcon>
                <ListItemText>
                  A new hospital to replace the aging 'Cottage Hospital' has
                  been approved by the Province of Ontario.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledCheckIcon />
                </ListItemIcon>
                <ListItemText>
                  Established a new, local, budget dedicated to seniors
                  programming. This budget has resulted in increased
                  programming, better information availability and staff
                  dedicated to supporting our aging community.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledCheckIcon />
                </ListItemIcon>
                <ListItemText>
                  Over $200K in Provincial and Federal grants have been received
                  and invested in infrastructure and programs targeted at active
                  seniors.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledCheckIcon />
                </ListItemIcon>
                <ListItemText>
                  The Seniors Centre is now fully accessible, Township staff is
                  on site to direct and serve residents and has a new lounge
                  designed and furnished specifically for an aging community.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledCheckIcon />
                </ListItemIcon>
                <ListItemText>
                  Uxbridge was declared an Age-Friendly community in 2020.
                </ListItemText>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        {/* As Chair of Finance */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: (theme: Theme) => theme.palette.secondary.main }}
              />
            }
          >
            <Typography variant="h6">As Chair of Finance</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <ListItemIcon>
                  <StyledCheckIcon />
                </ListItemIcon>
                <ListItemText>
                  For the first time ever, Bruce has guided Council to three
                  consecutive lower property tax increases. Never in the history
                  of Uxbridge has each tax increase been less than the year
                  before for three consecutive years. As operating costs were
                  reduced, this Council increased the commitment to
                  infrastructure. Spending on roads and other key capital assets
                  has increased from $500K prior to this term of council to
                  $1.6M per year in 2022.
                </ListItemText>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  )
}

export default WhatsBeenDoneView
