import { createTheme } from '@mui/material/styles'

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 770, // Standard mobile
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#071A3C',
      light: '#384763',
      dark: '#04122a',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#F5B21A',
      light: '#f7c147',
      dark: '#ab7c12',
      contrastText: '#071A3C',
    },
  },
})