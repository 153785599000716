import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Theme,
  Typography,
} from '@mui/material'
import React from 'react'
import { Button } from 'components'

interface NewsCardProps {
  imgUrl?: string
  heading?: string
  body: string
  link?: string
}

const NewsCard = ({ imgUrl, heading, body, link }: NewsCardProps) => {
  return (
    <Card key={heading} sx={{ maxWidth: 345 }}>
      {imgUrl && (
        <CardMedia component="img" alt="" height="200" image={imgUrl} />
      )}
      <CardContent>
        {heading && (
          <Typography gutterBottom variant="h5" component="div">
            {heading}
          </Typography>
        )}
        <Typography variant="body2" color="text.secondary">
          {body}
        </Typography>
      </CardContent>
      {link && (
        <CardActions>
          <Button
            label="Read More"
            onClick={() => {
              window.open(link, '_blank')
            }}
          />
        </CardActions>
      )}
    </Card>
  )
}

// {
//   imgUrl: 'https://source.unsplash.com/random',
//   heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
//   body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//   link: 'https://www.google.ca/',
// },

const newsLinks: NewsCardProps[] = [
  {
    imgUrl: '/images/bruce-garrod-long-term-care-announcement.jpeg',
    body: 'April 2022 - The Province announced their commitment to rebuild and expand the Oak Valley Hospital (formerly Uxbridge Cottage Hospital) AND build a 192-bed Long-Term Care facility.  Bruce lead the advocacy committee that resulted in the new LTC home being built that will replace ReachView.',
  },
  {
    imgUrl: '/images/bruce-garrod-seniors-centre-announcement.jpeg',
    body: "February 2022 - As a result of a grant, initiated as part of Bruce's support of Uxbridge being an Age-Friendly Community, the Federal and Provincial government fully funded a complete renovation of the washrooms at the Senior's Centre to make them fully accessible.",
  },
  {
    imgUrl: '/images/bruce-garrod-jennifer-oconnel-housing.jpeg',
    body: "February 2022 - Bruce met with MP Jennifer O'Connell to discuss how the Liberal's housing strategy can be utilized in Uxbridge. The goal is to help create alternative housing that is more affordable to Uxbridge residents.",
  },
  {
    imgUrl: '/images/bruce-garrod-herrema-parking-lot.jpeg',
    body: 'July 2021 - In response to concerns regarding vandalism in Barton Farms, Bruce arranged for posts to be installed at Herrema Park. Combined with the barricades preventing unauthorized access to the park and the addition of security cameras, disruptive activities have been eliminated.',
  },
  {
    imgUrl: '/images/bruce-garrod-neville-stop-sign.jpeg',
    body: 'June 2020 - Bruce worked with a resident who had lobbied for 10 years to improve safety in Barton Farms.  The four-way stops on Barton Lane and Dominion St have made the area much safer for pedestrians of all ages',
  },
  {
    imgUrl:
      '/images/bruce-garrod-forsythe-stormwater-pond-federal-announcement.jpeg',
    body: 'July 2021 - As Finance Chair, Bruce helped secure major grants from all levels of government to support updating aging infrastructure such as the South Balsam storm water management pond.',
  },
  {
    imgUrl: '/images/bruce-garrod-hospice-donation.jpeg',
    body: 'February 2020 - In support of the North Durham Hospice, Bruce supported Dr Steve Russell by making a motion that was supported by Council to have Uxbridge donate $40,000 to the building fund.',
  },
  {
    imgUrl: '/images/bruce-garrod-herrema-traffic-calming.jpeg',
    body: "August 2020 - In an effort to improve community safety and expand the Township's traffic calming plan, Herrema Boulevard and Nelkydd Lane had lane narrowing paint applied.",
  },
  {
    imgUrl: '/images/bruce-garrod-grand-opening-of-hela-spice-trail.jpeg',
    body: "October 2019 - Bruce worked with the executive team at Hela Spice to have them pay for and install a paved path connecting Reach St to 3rd Ave S.  This path made getting to and from schools on Plank's Lane easier and safer.",
  },
  {
    imgUrl: '/images/bruce-garrod-orange-shirt-group.jpeg',
    body: 'September 2019 - In support of Truth and Reconciliation efforts, Bruce participated in the walk to the event at Trinity United Church',
  },
  {
    imgUrl: '/images/bruce-garrod-building-youth-capacity-at-uss.jpeg',
    body: 'March 2020 - Bruce worked with Uxbridge Secondary School to encourage entrepreneurship in the Building Youth Capacity program.',
  },
  {
    imgUrl: '/images/bruce-garrod-trestle-bridge.jpeg',
    body: 'January 2020 - To create a path that would significantly reduce snowmobiles in Barton Farms, Bruce arranged for the Trestle Bridge to be covered with protective rubber donated by VicDom aggregates and open the Great Trail. This not only redirected snowmobiles out of Barton Farms but also made it easier for snowmobile riders to come into Uxbridge to access fuel and food.',
  },
  {
    imgUrl: '/images/bruce-garrod-alternate-member-of-regional-council.jpeg',
    body: "January 2019 - Bruce was selected by Uxbridge Council to become the Alternate Member of Durham Region Council.  His role was to act on behalf of the current member or Mayor Barton if they weren't available.",
  },
]

const NewsView = () => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: (theme: Theme) => theme.palette.primary.main,
          color: '#FFFFFF',
          px: 4,
          py: 8,
        }}
      >
        <Typography variant="h5">In The News</Typography>
        <Typography variant="body1" sx={{ maxWidth: '40rem' }}>
          As the Uxbridge Ward 3 Councillor and Chair of Finance, Bruce has
          appeared frequently in local newspapers and on the radio station.
          Throughout the 4-year term, Bruce has been involved in community
          events, spear-heading Ward 3 improvements, managing finance issues and
          making Uxbridge an Age-Friendly community.
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          py: 8,
          justifyContent: 'center',
          rowGap: 4,
          columnGap: 4,
        }}
      >
        {React.Children.toArray(
          newsLinks.map((link) => <NewsCard {...link} />),
        )}
      </Box>
    </Box>
  )
}

export default NewsView
