import { SxProps, Theme } from '@mui/material'
import { Button as MuiButton } from '@mui/material'
import { MouseEventHandler, ReactNode } from 'react'

const blueContainedStyle: SxProps<Theme> = {
  backgroundColor: (theme: Theme) => theme.palette.primary.main,
  ':hover': {
    backgroundColor: (theme: Theme) => theme.palette.primary.light,
    color: '#FFFFFF',
  },
} as const

const yellowContainedStyle: SxProps<Theme> = {
  backgroundColor: (theme: Theme) => theme.palette.secondary.main,
  color: (theme: Theme) => theme.palette.primary.main,
  ':hover': {
    backgroundColor: (theme: Theme) => theme.palette.secondary.light,
  },
} as const

const blueOutlinedStyle: SxProps<Theme> = {
  borderColor: (theme: Theme) => theme.palette.primary.main,
  ':hover': {
    borderColor: (theme: Theme) => theme.palette.primary.light,
  },
} as const

const yellowOutlinedStyle: SxProps<Theme> = {
  borderColor: (theme: Theme) => theme.palette.secondary.main,
  ':hover': {
    borderColor: (theme: Theme) => theme.palette.secondary.light,
  },
} as const

const whiteOutlinedStyle: SxProps<Theme> = {
  borderColor: '#FFFFFF',
  color: '#FFFFFF',
  ':hover': {
    borderColor: '#FFFFFF',
    backgroundColor: (theme: Theme) => theme.palette.primary.light,
  },
} as const

const blueTextStyle: SxProps<Theme> = {
  color: (theme: Theme) => theme.palette.primary.main,
  ':hover': {
    backgroundColor: (theme: Theme) => theme.palette.primary.light,
  },
} as const

const yellowTextStyle: SxProps<Theme> = {
  color: (theme: Theme) => theme.palette.primary.main,
  ':hover': {
    backgroundColor: (theme: Theme) => theme.palette.primary.light,
  },
} as const

const whiteTextStyle: SxProps<Theme> = {
  color: '#FFFFFF',
  ':hover': {
    backgroundColor: (theme: Theme) => theme.palette.primary.light,
  },
} as const
interface ButtonProps {
  label: string
  variant?: 'contained' | 'outlined' | 'text'
  onClick?: MouseEventHandler
  colour?: 'blue' | 'yellow' | 'white'
  fullWidth?: boolean
  endIcon?: ReactNode
  sx?: SxProps<Theme>
  type?: 'submit' | undefined
  disabled?: boolean
}

const Button = (props: ButtonProps) => {
  const {
    sx,
    type,
    label,
    endIcon,
    fullWidth,
    colour = 'blue',
    onClick = () => {},
    variant = 'contained',
    disabled,
  } = props

  const getStyle = (): SxProps<Theme> => {
    if (variant === 'contained') {
      if (colour === 'blue') {
        return blueContainedStyle
      } else if (colour === 'yellow') {
        return yellowContainedStyle
      } else if (colour === 'white') {
        return blueContainedStyle
      }
    } else if (variant === 'outlined') {
      if (colour === 'blue') {
        return blueOutlinedStyle
      } else if (colour === 'yellow') {
        return yellowOutlinedStyle
      } else if (colour === 'white') {
        return whiteOutlinedStyle
      }
    } else if (variant === 'text') {
      if (colour === 'blue') {
        return blueTextStyle
      } else if (colour === 'yellow') {
        return yellowTextStyle
      } else if (colour === 'white') {
        return whiteTextStyle
      }
    }

    return {}
  }

  const style = { borderRadius: '4px', ...getStyle(), ...sx } as SxProps<Theme>

  return (
    <MuiButton
      disabled={disabled}
      fullWidth={fullWidth}
      sx={style}
      variant={variant}
      onClick={onClick}
      endIcon={endIcon}
      type={type}
    >
      {label}
    </MuiButton>
  )
}

export default Button
