import { useNavigate } from 'react-router-dom'
import { Box, Theme, useMediaQuery, Typography } from '@mui/material'

import { Button } from 'components'

const Footer = ({
  setShowSignModal,
  setShowDonateModal,
}: {
  setShowSignModal: (arg0: boolean) => void
  setShowDonateModal: (arg0: boolean) => void
}) => {
  const navigate = useNavigate()
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme: Theme) => theme.palette.primary.main,
          minHeight: '8rem',
          display: 'flex',
          flexDirection: isTablet ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          columnGap: 2,
          rowGap: 2,
          py: isTablet ? 4 : 0,
        }}
      >
        <Button
          label="What Bruce Will Do"
          variant="text"
          colour="white"
          onClick={() => {
            navigate('/what-bruce-will-do')
          }}
        />
        <Button
          label="What Bruce Has Done"
          variant="text"
          colour="white"
          onClick={() => {
            navigate('/what-bruce-has-done')
          }}
        />
        <Button
          label="In the News"
          variant="text"
          colour="white"
          onClick={() => {
            navigate('/in-the-news')
          }}
        />
        <Button
          label="Contact"
          variant="text"
          colour="white"
          onClick={() => {
            navigate('/#contact')
          }}
        />

        {/* <Button
          label="Get a lawn sign"
          variant="contained"
          colour="yellow"
          onClick={() => {
            setShowSignModal(true)
          }}
        />
        <Button
          label="Donate"
          variant="outlined"
          colour="white"
          onClick={() => {
            setShowDonateModal(true)
          }}
        /> */}
      </Box>
      <Box
        sx={{
          backgroundColor: (theme: Theme) => theme.palette.primary.main,
          pb: 2,
          color: '#FFFFFF',
        }}
      >
        <Typography align="center" variant="subtitle1">
          Copyright &copy; 2022 Bruce Garrod
        </Typography>
      </Box>
    </>
  )
}

export default Footer
