import { useState, FormEventHandler } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  FormControlLabel,
} from '@mui/material'
import { Button } from 'components'
import axios from 'axios'
import CloseIcon from '@mui/icons-material/Close'

interface SignModalProps {
  isOpen: boolean
  setIsOpen: (arg0: boolean) => void
}

const SignModal = ({ isOpen, setIsOpen }: SignModalProps) => {
  const [hasFormSent, setHasFormSent] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    preferredDeliveryMethod: 'install',
    'form-name': 'sign',
  })

  const updateFormData = (newData: object) => {
    setFormData({ ...formData, ...newData })
  }

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault()

    axios
      .request({
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        url: '/',
        data: Object.keys(formData)
          .map(
            (key) =>
              encodeURIComponent(key) +
              '=' +
              encodeURIComponent(formData[key as keyof typeof formData]),
          )
          .join('&'),
      })
      .then(() => {
        setHasFormSent(true)
      })
      .catch((error) => console.error(error))
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
    >
      <DialogTitle>
        Request Lawn Sign
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsOpen(false)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {hasFormSent ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 2,
              width: '30rem',
              justifyContent: 'center',
              textAlign: 'center',
              py: 6,
            }}
          >
            <Typography>Thank you for your support</Typography>
            <Typography>You'll hear from me soon!</Typography>
          </Box>
        ) : (
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 2,
              width: '30rem',
            }}
          >
            <TextField
              label="Your Name"
              required
              value={formData.name}
              onChange={(e) => {
                updateFormData({ name: e.target.value })
              }}
            />
            <TextField
              label="Email Address"
              value={formData.email}
              onChange={(e) => {
                updateFormData({ email: e.target.value })
              }}
              type="email"
            />
            <TextField
              label="Phone Number"
              value={formData.phone}
              onChange={(e) => {
                updateFormData({ phone: e.target.value })
              }}
            />
            <FormControl>
              <FormLabel>Preferred Delivery</FormLabel>
              <RadioGroup
                row
                aria-labelledby="preferred-delivery-label"
                name="preferred-contact-method"
                value={formData.preferredDeliveryMethod}
                onChange={(e) => {
                  updateFormData({ preferredDeliveryMethod: e.target.value })
                }}
              >
                <FormControlLabel
                  value="install"
                  control={<Radio />}
                  label="Install it for me"
                />
                <FormControlLabel
                  value="drop-off"
                  control={<Radio />}
                  label="Drop it off"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              label="Address"
              required
              value={formData.address}
              onChange={(e) => {
                updateFormData({ address: e.target.value })
              }}
            />
            <Button type="submit" label="Send" onClick={onSubmit} />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default SignModal
