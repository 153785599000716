import ReactGA from 'react-ga'
import LogRocket from 'logrocket'
import { Box } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react'

import { Footer, MenuBar, MenuDrawer, SignModal, DonateModal } from 'components'

import {
  HomeView,
  NewsView,
  WhatsBeenDoneView,
  WhatsGoingToBeDone,
} from 'views'

const viewMargin = 0

ReactGA.initialize('UA-118667334-1')
LogRocket.init('vblvdz/bruce-garrod-election')

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  const topMenuBarRef = useRef<HTMLDivElement>(null)
  const [isSignModalOpen, setIsSignModalOpen] = useState(false)
  const [isDonateModalOpen, setIsDonateModalOpen] = useState(false)
  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false)
  const [topMenuBarHeight, setTopMenuBarHeight] = useState(
    topMenuBarRef.current?.offsetHeight,
  )

  window.addEventListener('resize', () => {
    setTopMenuBarHeight(topMenuBarRef.current?.offsetHeight)
  })

  useEffect(() => {
    setTopMenuBarHeight(topMenuBarRef.current?.offsetHeight)
  }, [topMenuBarRef])

  return (
    <>
      <SignModal isOpen={isSignModalOpen} setIsOpen={setIsSignModalOpen} />
      <DonateModal
        isOpen={isDonateModalOpen}
        setIsOpen={setIsDonateModalOpen}
      />
      <MenuDrawer
        show={isMenuDrawerOpen}
        setShow={setIsMenuDrawerOpen}
        topMenuBarHeight={topMenuBarHeight}
        setShowSignModal={setIsSignModalOpen}
        setShowDonateModal={setIsDonateModalOpen}
      />
      <Box>
        <MenuBar
          ref={topMenuBarRef}
          isMenuDrawerOpen={isMenuDrawerOpen}
          setIsMenuDrawerOpen={setIsMenuDrawerOpen}
          setShowSignModal={setIsSignModalOpen}
          setShowDonateModal={setIsDonateModalOpen}
        />
        <Box
          sx={{
            display: 'flex',
            m: `${viewMargin}px`,
            flexDirection: 'column',
            mt: `${(topMenuBarHeight || 0) + viewMargin}px`,
            minHeight: `calc(100vh - ${
              (topMenuBarHeight || 0) + viewMargin * 2
            }px)`,
          }}
        >
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="/in-the-news" element={<NewsView />} />
            <Route
              path="/what-bruce-has-done"
              element={<WhatsBeenDoneView />}
            />
            <Route
              path="/what-bruce-will-do"
              element={<WhatsGoingToBeDone />}
            />
          </Routes>
        </Box>
        <Footer
          setShowSignModal={setIsSignModalOpen}
          setShowDonateModal={setIsDonateModalOpen}
        />
      </Box>
    </>
  )
}

export default App
