import {
  Box,
  List,
  Theme,
  ListItem,
  Accordion,
  Typography,
  ListItemIcon,
  ListItemText,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'
import AddBoxIcon from '@mui/icons-material/AddBox'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import downtownImage from 'assets/images/bruce-garrod-downtown-uxbridge.jpg'

const StyledAddBoxIcon = () => (
  <AddBoxIcon
    sx={{
      color: (theme: Theme) => theme.palette.primary.main,
    }}
  />
)

const WhatsGoingToBeDone = () => {
  return (
    <Box>
      <Box
        component="img"
        src={downtownImage}
        sx={{
          width: '100%',
          height: '40vw',
          maxHeight: '30rem',
          objectFit: 'cover',
          objectPosition: '50% 65%',
          mb: '-5px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: (theme: Theme) => theme.palette.primary.main,
          color: '#FFFFFF',
          px: 4,
          py: 8,
        }}
      >
        <Typography variant="h5">Bruce's Pledge to You</Typography>
        <Typography variant="body1" sx={{ maxWidth: '40rem' }}>
          Durham Region is experiencing a period of phenomenal growth — the
          population is expected to double within 30 years and Uxbridge will be
          part of the rise in population. A realistic plan must be built that
          will allow controlled growth in Uxbridge while improving the services
          provided by Durham Region (police services, waste management,
          water/sewer, etc.).
        </Typography>
        <Typography variant="body1" sx={{ maxWidth: '40rem' }}>
          With 57% of property tax bills being directed to Durham Region,
          Uxbridge deserves and will need more support.
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: '60rem',
          mx: 'auto',
          my: 6,
        }}
      >
        {/* Enhancing Regional Services */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: (theme: Theme) => theme.palette.secondary.main }}
              />
            }
          >
            <Typography variant="h6">Enhancing Regional Services</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  Police services represent 30% of the Regional portion of
                  property taxes. Bruce will bring increased, consistent
                  visibility of DRPS in Uxbridge.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  21% of the Durham Region property taxes are directed to social
                  services. Bruce will initiate a program to determine what
                  social services should be prioritized in Uxbridge. He will
                  then advocate for targeted improvements for the prioritized
                  areas.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  Durham Region Transit (DRT) has implemented on-demand service.
                  Bruce will meet with DRT management to propose a model that
                  better serves neighbourhood residents. Further, Bruce will
                  press DRT to broadly communicate the on-demand service.
                </ListItemText>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        {/* Community Engagement */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: (theme: Theme) => theme.palette.secondary.main }}
              />
            }
          >
            <Typography variant="h6">Community Engagement</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  Bruce will create a program to broadly communicate Regional
                  activities to the urban and rural areas as well as the hamlets
                  by a mix of local newspapers, radio, townhall meetings and
                  social media.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  Downtown revitalization will be a focus of the next Uxbridge
                  Council. The redevelopment will be focused on Regional Roads.
                  Bruce will engage with the committee to lobby for Durham
                  Region support for proposed enhancements.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  Expand efforts to ensure all residents feel safe, represented
                  and able to be their best selves. Whether it be 2SLGBTQ+,
                  local Indigenous community members, or other marginalized or
                  underrepresented individuals, Bruce will continue to listen,
                  and seek opportunities to ensure their voices are heard.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  Bruce will pursue his initiative to explore Durham Region
                  purchasing and developing lands devastated by the May '22
                  tornado and continue to work with the United Church of Canada
                  and Trinity United Church to develop a geared-to-income rental
                  complex. The Federal and Provincial governments both have
                  housing affordability programs. Bruce will continue to
                  leverage his relationships with MPP Minister Bethlenfalvy and
                  MP Jennifer O'Connell to determine how these programs can be
                  implemented in Uxbridge.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  Bruce will continue to support the Uxbridge arts committee.
                  Specifically, including various art forms in the downtown
                  redevelopment plan and at the Fields of Uxbridge
                </ListItemText>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        {/* Environment and Sustainability */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: (theme: Theme) => theme.palette.secondary.main }}
              />
            }
          >
            <Typography variant="h6">Environment and Sustainability</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  Bruce will initiate programs to collaborate with Durham
                  Region, the Province of Ontario and the Federal Government to
                  reduce Township carbon emissions, protect and manage
                  green-space and ensure growth is viewed through an
                  environmentally sustainable lens.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  Bruce will support continued investment in maintaining local
                  trails in order to maintain the Trail Capital of Canada brand.
                  Bruce will also continue efforts to link trails south of RR21
                  to the network of trails leading into the urban area. This
                  strategy will draw day-use trail users into town to support
                  local businesses and promote local tourism.
                </ListItemText>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        {/* Rural and Hamlet Support */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: (theme: Theme) => theme.palette.secondary.main }}
              />
            }
          >
            <Typography variant="h6">Rural and Hamlet Support</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  Bruce will expand relationships with Enbridge to encourage
                  more natural gas network expansion to farm properties and
                  Uxbridge's hamlets.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  Highspeed internet service must be considered a utility. Bruce
                  will work with local internet service providers (ISP's) to
                  leverage the Regional infrastructure currently being
                  implemented. Further, Bruce will leverage his relationship
                  with the Federal MP to encourage its nationwide highspeed
                  access be implemented within rural Uxbridge.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  In partnership with existing Regional resources, Bruce will
                  lobby to host a summit with representative farmers and Federal
                  delegates to ensure the Federal government understands the
                  local Uxbridge impact of Federal policy changes.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StyledAddBoxIcon />
                </ListItemIcon>
                <ListItemText>
                  Bruce will consult with hamlets and rural residents to
                  determine interest levels in rotating townhall meetings to
                  improve communications related to Regional issues.
                </ListItemText>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  )
}

export default WhatsGoingToBeDone
