import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const DonateModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (arg0: boolean) => void
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
    >
      <DialogTitle>
        <Typography variant="h6" sx={{ mr: 4 }}>
          Donate to Bruce's Campaign
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsOpen(false)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <ListItemText>
              <Box sx={{ width: '30rem', textAlign: 'center' }}>
                Interact E-Transfer your donation to{' '}
                <Typography
                  variant="body1"
                  component="span"
                  sx={{ fontWeight: '500' }}
                >
                  Bruce@BruceGarrod.ca
                </Typography>
              </Box>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Box sx={{ width: '30rem', textAlign: 'center' }}>or</Box>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Box sx={{ width: '30rem', textAlign: 'center' }}>
                for cash or cheque, contact Bruce at{' '}
                <Box
                  component="a"
                  href="tel:14164583515"
                  sx={{
                    colour: (theme: Theme) => theme.palette.primary.main,
                    ':-webkit-any-link': {
                      color: (theme: Theme) => theme.palette.primary.main,
                    },
                  }}
                >
                  (416) 458 3515
                </Box>{' '}
                or{' '}
                <Box
                  component="a"
                  href="mailto:bruce@brucegarrod.ca"
                  sx={{
                    colour: (theme: Theme) => theme.palette.primary.main,
                    ':-webkit-any-link': {
                      color: (theme: Theme) => theme.palette.primary.main,
                    },
                  }}
                >
                  Bruce@BruceGarrod.ca
                </Box>
              </Box>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Box sx={{ width: '30rem', textAlign: 'center' }}>
                <Typography variant="body2">
                  Donations of up to $1200 can be made to the campaign by an
                  individual. Business or corporate donations are prohibited by
                  law.
                </Typography>
              </Box>
            </ListItemText>
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default DonateModal
